// class definition
import { LitElement, TemplateResult, html } from '@horizon/base';
import { HasSlotController } from '@horizon/common/controllers';
import { property } from '@horizon/base/decorators';
import { nothing } from '@horizon/base/html.js';

import BadgeStyles from './badge.css.js';
import { HznBadgeTone } from '../types.js';

/**
 *
 * @tag hzn-badge
 * @tagname hzn-badge
 * @summary badge that can pass required information based on tone and intensity.
 */

export class HznBadge extends LitElement {
  static styles = [BadgeStyles];

  private readonly hasSlotController = new HasSlotController(this, '[default]', 'trailing-icon');

  /**
   * Sets the label for badge
   */
  @property({ type: String, reflect: true }) label = 'Badge';

  /**
   * Sets the background color and text for badge
   * @playroomValues {'critical' | 'caution' | 'info' | 'neutral' | 'positive' | 'flagged'}
   */
  @property({ type: String, reflect: true }) tone?: HznBadgeTone = 'neutral';

  /**
   * When true, increases intensity of background color and text for badge
   */
  @property({ type: Boolean, reflect: true }) intense?: boolean = false;

  render(): TemplateResult {
    return html` <div class="hzn-badge" role="status">
      ${this.hasSlotController.test('leading-icon') ? html`<slot class="leading-icon" name="leading-icon"></slot>` : nothing}
      <span class="label">${this.label || `${this.tone} badge`}</span>
    </div>`;
  }
}
