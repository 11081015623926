import { LitElement, html, TemplateResult } from '@horizon/base';
import { classMap } from '@horizon/base/directives';
import LoadingStyles from './loading.css.js';
import { property } from '@horizon/base/decorators.js';
import { HznLoadingOn, HznLoadingSize, HznLoadingTone } from '../types.js';

/**
 *
 * @tag hzn-loading
 * @tagname hzn-loading
 * @summary An animated loading indicator component to show activity / progress of an indeterminate operation.
 */

export class HznLoading extends LitElement {
  static styles = [LoadingStyles];

  /**
   * Sets the color of the loading element
   * @playroomValues {'brand' | 'inverse' }
   */
  @property({ type: String }) tone?: HznLoadingTone = 'brand';

  /**
   * Sets the size of the loading element
   * @playroomValues {'small' | 'medium' | 'large'}
   */
  @property({ type: String }) size?: HznLoadingSize = 'small';

  /**
   * Sets the background color that this loader is appearing on top of in order to change its color accordingly
   * @playroomValues {'brand' | 'brand-accent'}
   */
  @property({ type: String }) on?: HznLoadingOn;

  /**
   * Label value for the loading component
   */
  @property({ type: String }) label: string = 'Loading';



  render(): TemplateResult {

    return html`<div role='status' aria-live='polite' aria-label=${this.label}>
      <svg
        role="progressbar"
        class="${classMap({
          'spinner': true,
          'is-tone-brand': this.tone === 'brand',
          'is-tone-inverse': this.tone === 'inverse',
          'is-on-brand': this.on === 'brand',
          'is-on-brand-accent': this.on === 'brand-accent',
          'is-small': this.size === 'small',
          'is-medium': this.size === 'medium',
          'is-large': this.size === 'large'
        })}"
      >
        <circle class="spinner-indicator"></circle>
      </svg>
    </div>`;
  }
}


