// class definition
import { LitElement } from '@horizon/base';
import { property } from '@horizon/base/decorators.js';

import { HznIconSize } from '../types.js';
import iconStyles from './icon.css.js';
export class HznIconBase extends LitElement {
  static styles = [iconStyles];

  connectedCallback(): void {
    super.connectedCallback();
    this.setAttribute('inert', '');
  }

  /**
   * The size of the icon
   * @playroomValues {'small' | 'base' | 'large'}
   */
  @property({ type: String, reflect: true }) size?: HznIconSize = 'base';
}
