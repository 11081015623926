
// GENERATED FILE, DO NOT MANUALLY EDIT

import { TemplateResult, html } from '@horizon/base';
import { HznIconBase } from '../../icon/icon-base.js';
import './types.js';

/**
 *
 * @tag hzn-icon-location
 * @tagname hzn-icon-location
 * @summary An individual icon for Horizon
 */
export class HznIconLocation extends HznIconBase {

  render(): TemplateResult {
    return html`<div class="hzn-icon" aria-hidden="true"><svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6 9.26114C6 6.38925 8.42708 3.99829 11.5 3.99829C14.5729 3.99829 17 6.38925 17 9.26114C17 10.7012 16.2125 12.6547 14.9647 14.6823C13.9431 16.3425 12.6805 17.9443 11.5 19.1912C10.3195 17.9443 9.05689 16.3425 8.03525 14.6823C6.78751 12.6547 6 10.7012 6 9.26114ZM11.5 1.99829C7.39322 1.99829 4 5.21527 4 9.26114C4 11.2799 5.04061 13.6321 6.33193 15.7305C7.64565 17.8653 9.32368 19.9043 10.8062 21.3327L11.5 22.0012L12.1938 21.3327C13.6763 19.9043 15.3544 17.8653 16.6681 15.7305C17.9594 13.6321 19 11.2799 19 9.26114C19 5.21527 15.6068 1.99829 11.5 1.99829ZM11.5342 11.6278C12.8874 11.6278 13.9845 10.5741 13.9845 9.27433C13.9845 7.97453 12.8874 6.92084 11.5342 6.92084C10.1809 6.92084 9.08386 7.97453 9.08386 9.27433C9.08386 10.5741 10.1809 11.6278 11.5342 11.6278Z" fill="currentColor"></path>
</svg></div>`;
  }
}
