
// GENERATED FILE, DO NOT MANUALLY EDIT

import { TemplateResult, html } from '@horizon/base';
import { HznIconBase } from '../../icon/icon-base.js';
import './types.js';

/**
 *
 * @tag hzn-icon-chat
 * @tagname hzn-icon-chat
 * @summary An individual icon for Horizon
 */
export class HznIconChat extends HznIconBase {

  render(): TemplateResult {
    return html`<div class="hzn-icon" aria-hidden="true"><svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3 5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V15C21 16.1046 20.1046 17 19 17H12.3508L9.24939 19.4811C7.93986 20.5287 6 19.5964 6 17.9194V17H5C3.89543 17 3 16.1046 3 15V5ZM19 5L5 5V15H6C7.10457 15 8 15.8954 8 17V17.9194L11.1014 15.4383C11.456 15.1546 11.8966 15 12.3508 15H19V5Z" fill="currentColor"></path>
</svg></div>`;
  }
}
