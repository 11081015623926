
// GENERATED FILE, DO NOT MANUALLY EDIT

import { TemplateResult, html } from '@horizon/base';
import { HznIconBase } from '../../icon/icon-base.js';
import './types.js';

/**
 *
 * @tag hzn-icon-arrow-right
 * @tagname hzn-icon-arrow-right
 * @summary An individual icon for Horizon
 */
export class HznIconArrowRight extends HznIconBase {

  render(): TemplateResult {
    return html`<div class="hzn-icon" aria-hidden="true"><svg viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.6209 11.8476L13.1875 7.41421L14.6018 6L20.7422 12.1405C21.1327 12.531 21.1327 13.1642 20.7422 13.5547L14.6018 19.6952L13.1875 18.2809L17.6209 13.8476H1V11.8476H17.6209Z" fill="currentColor"></path>
</svg></div>`;
  }
}
