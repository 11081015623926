import { LitElement, TemplateResult } from '@horizon/base';
import { property } from '@horizon/base/decorators.js';
import { unsafeStatic, html } from '@horizon/base/static-html.js';

import InlineStyles from './inline.css.js';
import {
  HznInlineAlignX,
  HznInlineAlignY,
  HznInlineAs,
  HznInlineCollapseBelow,
  HznInlineSpace,
  inlineTags
} from '../types.js';



/**
 *
 * @tag hzn-inline
 * @tagname hzn-inline
 * @summary A layout component for establishing a horizontal row of items
 */
export class HznInline extends LitElement {
  static styles = [InlineStyles];

  /**
   * Set the gap between inline items. Accepts a single value, or two values separated by a space. When two values are provided the order is space="horizontal vertical", ie space="large small" sets the column-gap to 'small' and the row-gap to large
   * @playroomValues {'none' | 'xxsmall' | 'xsmall' | 'small' | 'base' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'xxxlarge'}
   */
  @property({ type: String, reflect: true }) space?: HznInlineSpace = 'base';

  /**
   * Set the HTML element the inline element will render as (div, ol, ul)
   * @playroomValues {'ul' | 'ol' | 'div'}
   */
  @property({ type: String }) as?: HznInlineAs = 'div';

  /**
   * Set the responsive breakpoint below which all inlined elements will collapse into a single column
   * @playroomValues {'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge'}
   */
  @property({ type: String, reflect: true, attribute: 'collapse-below' }) collapseBelow?: HznInlineCollapseBelow;

  /**
   * Set the vertical alignment of the child elements relative to each other in their flex row
   * @playroomValues {'stretch' | 'start' | 'end' | 'center' | 'baseline'}
   */
  @property({ type: String, reflect: true, attribute: 'align-y' }) alignY?: HznInlineAlignY = 'center';

  /**
   * Set the horizontal alignment of the child elements relative to the hzn-inline container
   * @playroomValues {'stretch' | 'start' | 'end' | 'center' | 'between' | 'evenly' | 'around'}
   */
  @property({ type: String, reflect: true, attribute: 'align-x' }) alignX?: HznInlineAlignX = 'start';

  /**
   * Reverse the order of inline child elements
   */
  @property({ type: Boolean, reflect: true }) reverse?: boolean = false;

  /**
   * Set inlined child elements to wrap to the next row
   */
  @property({ type: Boolean, attribute: 'wrap', reflect: true }) wrap?: boolean = false;

  #computeSpaceClass() {
    if (this.space) {
      const spaceSplit = this.space.split(' ');
      if (spaceSplit.length > 1) {
        return `spacing-${spaceSplit[0]}-${spaceSplit[1]}`;
      }
      return `spacing-${spaceSplit[0]}`;
    }
    return 'spacing-base';
  }

  render(): TemplateResult {
    return html`<${unsafeStatic(this.as && inlineTags.includes(this.as) ? this.as : 'div')} class="outer ${this.#computeSpaceClass()}">
      <slot></slot>
    </${unsafeStatic(this.as && inlineTags.includes(this.as) ? this.as : 'div')}>`;
  }
}
