import { LitElement, TemplateResult } from '@horizon/base';
import { unsafeStatic, html } from '@horizon/base/static-html.js';
import { property } from '@horizon/base/decorators.js';

import cardStyles from './card.css.js';
import {
  AllowedCardElements,
  HznCardBorderRadius,
  HznCardElevation,
  HznCardPadding,
  HznCardRoundedAbove,
  HznCardTone,
  HznCardAs
} from '../types.js';

/**
 *
 * @tag hzn-card
 * @tagname hzn-card
 * @summary A component for creating cards
 */
export class HznCard extends LitElement {
  static styles = [cardStyles];

  /**
   * Sets the background color of card
   * @playroomValues {'neutral' | 'brand' | 'brand-accent'}
   */
  @property({ type: String, reflect: true }) tone: HznCardTone = 'neutral';

  /**
   * Sets the border radius of card
   * @playroomValues {'none' | 'small' | 'base' | 'medium' | 'large'}
   */
  @property({ type: String, reflect: true, attribute: 'border-radius' }) borderRadius?: HznCardBorderRadius = 'none';

  /**
   * Sets the box shadow/elevation of card
   * @playroomValues {'none' | 'flat' | 'small' | 'medium' | 'large' | 'xlarge'}
   */
  @property({ type: String, reflect: true }) elevation?: HznCardElevation = 'none';

  /**
   * Sets the padding of card
   * @playroomValues {'none' | 'xxsmall' | 'xsmall' | 'small' | 'base' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'xxxlarge'}
   */
  @property({ type: String, reflect: true }) padding?: HznCardPadding = 'base';

  /**
   * Sets the html tag used for card
   * @playroomValues {'div' | 'article' | 'details' | 'main' | 'section' | 'aside'}
   */
  @property({ type: String, reflect: true }) as?: HznCardAs = 'div';

  /**
   * Sets the breakpoint for border-radius. If border radius is set, it will only be applied above the specified breakpoint
   * @playroomValues {'small' | 'medium' | 'large' | 'xlarge'}
   */
  @property({ type: String, reflect: true, attribute: 'rounded-above' }) roundedAbove?: HznCardRoundedAbove;

  render(): TemplateResult {
    return html`<${unsafeStatic(this.as && AllowedCardElements.includes(this.as) ? this.as : 'div')} class="hzn-card">
    <slot></slot>
    </${unsafeStatic(this.as && AllowedCardElements.includes(this.as) ? this.as : 'div')}>`;
  }
}
