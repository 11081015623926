
// GENERATED FILE, DO NOT MANUALLY EDIT

import { TemplateResult, html } from '@horizon/base';
import { HznIconBase } from '../../icon/icon-base.js';
import './types.js';

/**
 *
 * @tag hzn-icon-caret-right
 * @tagname hzn-icon-caret-right
 * @summary An individual icon for Horizon
 */
export class HznIconCaretRight extends HznIconBase {

  render(): TemplateResult {
    return html`<div class="hzn-icon" aria-hidden="true"><svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.7933 17.6569L16.6217 11.8285L10.7933 6.00006" stroke="currentColor" stroke-width="2" stroke-linejoin="round"></path>
</svg></div>`;
  }
}
