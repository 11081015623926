import { LitElement, TemplateResult } from '@horizon/base';
import { property } from '@horizon/base/decorators.js';
import { unsafeStatic, html } from '@horizon/base/static-html.js';

import HeadingStyles from './heading.css.js';
import { HznHeadingAlign, HznHeadingAs, HznHeadingLineHeight, HznHeadingOn, HznHeadingSize, HznHeadingTone, headingTags } from '../types.js';



/**
 *
 * @tag hzn-heading
 * @tagname hzn-heading
 * @summary A text component for adding and styling heading tags
 */
export class HznHeading extends LitElement {
  static styles = [HeadingStyles];

  /**
   * Sets the text-align of text along the x-axis
   * @playroomValues {'left' | 'center' | 'right'}
   */
  @property({ type: String, reflect: true }) align?: HznHeadingAlign = 'left';

  /**
   * Sets the heading tag of the shadow element
   * @playroomValues {'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'}
   */
  @property({ type: String, reflect: true }) as?: HznHeadingAs = 'h2';

  /**
   * Sets the color of text within element
   * @playroomValues {'brand' | 'brand-accent' | 'inverse' | 'neutral' | 'subdued'}
   */
  @property({ type: String, reflect: true }) tone?: HznHeadingTone =
    'brand';

  /**
   * Sets the font size of text within element
   * @playroomValues {'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge'}
   */
  @property({ type: String, reflect: true }) size?: HznHeadingSize =
    'medium';

  /**
   * Sets the line height of text within element
   * @playroomValues {'none' | 'xsmall' | 'small' | 'base' | 'large' | 'xlarge'}
   */
  @property({ type: String, reflect: true, attribute: 'line-height' }) lineHeight?: HznHeadingLineHeight = 'base';

  /**
   * Sets the background color that this heading is appearing on top of in order to change its color accordingly
   * @playroomValues {'brand' | 'brand-accent'}
   */
  @property({ type: String, reflect: true }) on?: HznHeadingOn;

  render(): TemplateResult {
    return html`<${unsafeStatic(this.as && headingTags.includes(this.as) ? this.as : 'h2')} class="heading-cont ${this.size}">
      <slot></slot>
    </${unsafeStatic(this.as && headingTags.includes(this.as) ? this.as : 'h2')}>`;
  }
}
