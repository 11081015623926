// class definition
import { LitElement, TemplateResult } from '@horizon/base';
import { unsafeStatic, html } from '@horizon/base/static-html.js';
import { property } from '@horizon/base/decorators.js';
import { classMap } from '@horizon/base/directives.js';

import TextStyles from './text.css.js';
import {
  BlockElements,
  HznTextAlign,
  HznTextAs,
  HznTextLineHeight,
  HznTextOn, HznTextSize,
  HznTextTone,
  HznTextVariant,
  HznTextWeight,
  InlineElements
} from '../types.js';



/**
 *
 * @tag hzn-text
 * @tagname hzn-text
 * @summary A text component for adding and styling text
 */
export class HznText extends LitElement {
  static styles = [TextStyles];

  /**
   * @Private
   */
  get #isBlockStyle() {
    return [...BlockElements].includes(this.as as typeof BlockElements[number]) && !this.inline;
  }

  /**
   * Sets whether the hzn-text host element should be `display: inline`. Defaults to `false`
   */
  @property({ type: Boolean, reflect: true }) inline?: boolean = false;

  /**
   * Sets the variant/style of the text to be displayed
   * @playroomValues {'text' | 'caption' | 'callout' | 'eyebrow'}
   */
  @property({ type: String, reflect: true }) variant?: HznTextVariant = 'text';

  /**
   * Sets the font size of text within element
   * @playroomValues {'small' | 'base' | 'large'}
   */
  @property({ type: String, reflect: true }) size?: HznTextSize = 'base';

  /**
   * Sets the color of text within element
   * @playroomValues {'body' | 'brand' | 'subdued' | 'critical' | 'caution' | 'positive' | 'info' | 'flagged' | 'interactive' | 'disabled' | 'inverse'}
   */
  @property({ type: String, reflect: true }) tone?: HznTextTone = 'body';

  /**
   * Sets the font weight of text within element
   * @playroomValues {'base' | 'bold'}
   */
  @property({ type: String, reflect: true }) weight?: HznTextWeight = 'base';

  /**
   * Sets the text-align of text along the x-axis
   * @playroomValues {'left' | 'center' | 'right'}
   */
  @property({ type: String, reflect: true }) align?: HznTextAlign = 'left';

  /**
   * Sets the tag of the shadow element
   * @playroomValues {'span' | 'div' | 'sub' | 'sup' | 'p' | 'li'}
   */
  @property({ type: String, reflect: true }) as?: HznTextAs = 'p';

  /**
   * Sets the line height of text within element
   * @playroomValues {'none' | 'xsmall' | 'small' | 'base' | 'large' | 'xlarge'}
   */
  @property({ type: String, reflect: true, attribute: 'line-height' }) lineHeight?: HznTextLineHeight = 'base';

  /**
   * Truncates the text (This is...)
   */
  @property({ type: Boolean, reflect: true }) truncate?: boolean = false;

  /**
   * Sets the background color that this text is appearing on top of in order to change its color accordingly
   * @playroomValues {'brand' | 'brand-accent'}
   */
  @property({ type: String, reflect: true }) on?: HznTextOn;

  render(): TemplateResult {
    const classes = {
      'text-cont': true,
      'capsize': this.#isBlockStyle
    };

    const isValidAs = this.as ? [...InlineElements, ...BlockElements].includes(this.as) : false;

    // DONT FORMAT to add new lines. This component can be display:inline and the whitespace in it will be included.
    return html`<${unsafeStatic(this.as && isValidAs ? this.as : 'p')} class="${classMap(classes)}"><slot></slot></${unsafeStatic(this.as && isValidAs ? this.as : 'p')}>`;
  }
}
