import { CustomElement } from '@horizon/common/types';
import { HznCard } from './card/card.js';

declare global {
  interface HTMLElementTagNameMap {
    'hzn-card': HznCard;
  }
  namespace JSX {
    interface IntrinsicElements {
      ['hzn-card']: CustomElement<HznCardHTML, HznCard>;
    }
  }
}


export const AllowedCardElements = ['div', 'article', 'details', 'main', 'section', 'aside'];
export type HznCardAs = typeof AllowedCardElements[number];

export type HznCardTone = 'neutral' | 'brand' | 'brand-accent';
export type HznCardBorderRadius = 'none' | 'small' | 'base' | 'medium' | 'large';
export type HznCardElevation = 'none' | 'flat' | 'small' | 'medium' | 'large' | 'xlarge';
export type HznCardPadding = 'none' | 'xxsmall' | 'xsmall' | 'small' | 'base' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'xxxlarge';
export type HznCardRoundedAbove = 'small' | 'medium' | 'large' | 'xlarge';

export type HznCardHTML = {
  tone?: HznCardTone;
  elevation?: HznCardElevation;
  padding?: HznCardPadding;
  as?: HznCardAs;
  'border-radius'?: HznCardBorderRadius;
  'rounded-above'?: HznCardRoundedAbove;
}
